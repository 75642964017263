import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components

// core components
import DashboardHeader from "components/Headers/DashboardHeader.js";

// import CardTeamMembers from "components/Cards/Dashboard/CardTeamMembers.js";
import CardAssignedCustomers from "components/Cards/Dashboard/CardAssignedCustomers.js";
import CardFollowUps from "components/Cards/Dashboard/CardFollowUps.js";
import CardCalls from "components/Cards/Dashboard/CardCalls.js";

import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
// import { Auth } from "aws-amplify";
import { API, graphqlOperation } from 'aws-amplify';
import { DataStore } from "@aws-amplify/datastore";
import * as subscriptions from '../../../graphql/subscriptions';

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

function Dashboard(props) {

  React.useEffect(() => {
    
    console.log('Llegamos a Dashboard');
  
    let code = localStorage.getItem('code');
    let state = localStorage.getItem('state');
    let redirect_uri = localStorage.getItem('redirect_uri');

    if (code && (code !== 'null')
        && state 
        && redirect_uri && (redirect_uri !== "null")) {
      // Redirect for Alexa Account Linking
      const newRedirectUri = `${redirect_uri}?state=${state}&code=${code}`;
      localStorage.setItem('redirect_uri', null);
      localStorage.setItem('code', null);
      console.log(newRedirectUri);
      window.location.href = newRedirectUri;
    }

    const subscription = API.graphql(
        graphqlOperation(subscriptions.onCreateHistory)
    ).subscribe({
        next: async ({ provider, value }) => {
          await DataStore.clear()
          await DataStore.start()
          console.log({ provider, value })
        },
        error: error => console.warn(error)
    });

    return () => subscription.unsubscribe();

  }, []);

  

  const classes = { ...useStyles(), ...useStylesCardDeck() };
  
  return (
    <>
      <DashboardHeader section="Panel de control" subsection="Inicio" />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} xl={6}>
            <CardCalls history={props.history} customerId="all" />
            <CardFollowUps history={props.history} customerId="all" />
          </Grid>
          <Grid item xs={12} xl={6}>
            <CardFollowUps history={props.history} type={"unassigned"} customerId="all" />
            <CardAssignedCustomers history={props.history} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Dashboard;
