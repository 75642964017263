import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import Toolbar from "@material-ui/core/Toolbar";

// core components
import componentStyles from "assets/theme/components/navbars/index-navbar.js";

const useStyles = makeStyles(componentStyles);

export default function OcasoNavbar({forceStyle}) {
  const classes = useStyles();
  
  return (
    <>
      <AppBar
        position="relative"
        color="transparent"
        elevation={0}
        className={forceStyle}
        classes={{ root: classes.ocasoBarRoot }}
      >
        <Toolbar>
          <Container
            display="flex!important"
            justifyContent="space-between"
            alignItems="center"
            component={Box}
            maxWidth="xl"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
            <a
              href="/index"
              rel="noopener noreferrer"
            >
              <Box
                alt="..."
                height="30px"
                component="img"
                marginRight="1rem"
                className={classes.headerImg}
                src={require("assets/img/brand/logo.svg").default}
              />
            </a>
              
            </Box>
            
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
