import React from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/es";
import ReactBSAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FilledInput from "@material-ui/core/FilledInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Slide from "@material-ui/core/Slide";
// import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { Divider, OutlinedInput } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";



// AMPLIFY
// import { API } from 'aws-amplify';
import { API, graphqlOperation, SortDirection } from 'aws-amplify';
import * as subscriptions from '../../../graphql/subscriptions';
// import { DataStore, Predicates } from "@aws-amplify/datastore";
import { DataStore } from "@aws-amplify/datastore";
import { FollowUp, Customer, User, HistoryRecordType, History } from "../../../models";
// import { listFollowUps } from "graphql/queries";

// core components
import componentStyles from "assets/theme/components/cards/dashboard/card-to-do-list.js";
import componentStylesButtons from "assets/theme/components/button.js";
import Check from "@material-ui/icons/Check";
import Person from "@material-ui/icons/Person";

// import { createHistory } from "graphql/mutations";
import Event from "@material-ui/icons/Event";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Create from "@material-ui/icons/Create";
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const customStyles = makeStyles(({
  cardStatsCustom: {
    marginBottom: '1.5rem!important',
  },
  historyContent: {
    padding: 0,
    overflow: 'auto',
    maxHeight: '300px'
  }  
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function CardFollowUps(props) {
  const classes = { ...useStyles(), ...useStylesButtons(), ...customStyles() };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [state, setState] = React.useState([]);
  const [customerId, setCustomerId] = React.useState(props.customerId);
  const [modalAdd, setModalAdd] = React.useState(false);
  const [eventDescription, setEventDescription] = React.useState('');
  // const [followUpId, setFollowUpId] = React.useState(undefined);
  // const [followUpVersion, setFollowUpVersion] = React.useState(undefined);
  const [followUpType] = React.useState(props.type ? props.type : "assigned")

  const [alert, setAlert] = React.useState(null);

  const [event, setEvent] = React.useState(undefined)
  const [modalChange, setModalChange] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [eventTime, setEventTime] = React.useState(null);
  const [eventDate, setEventDate] = React.useState(null);
  const [radios, setRadios] = React.useState(null);
  const [eventId, setEventId] = React.useState(null);
  // const [eventVersion, setEventVersion] = React.useState(null);
  const [eventTitle, setEventTitle] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  
  // const [customers, setCustomers] = React.useState([]);
  const [customer, setCustomer] = React.useState(undefined);
  const [customerIdSelected, setCustomerIdSelected] = React.useState({value: "", label: ""});

  const [users, setUsers] = React.useState([]);
  const [userIdSelected, setUserIdSelected] = React.useState({value: "", label: ""});


  const setCustomerView = (customerId) => {
    props.history.push('/admin/customer?id='+customerId);
  }


  async function updateFollowUpMutation(followUpData) {
    let userId = undefined
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData && userData.id) {
      userId = userData.id;
    }
    console.log('updateFollowUpMutation input:', followUpData)
    console.log('userIdSelected',  userIdSelected)
    const { id, date, status, description, title, customerId, _version } = followUpData
    console.log('Update con:',{ id, date, status, description, title, customerId, _version })
    const original = await DataStore.query(FollowUp, id);
    const user = await DataStore.query(User, userIdSelected.value || userId)
    const custUpdated = FollowUp.copyOf(original, updated => {
      updated.id = id
      updated.date = new Date(date).toISOString()
      updated.status = status
      // updated.reason = title
      updated.notes = description
      updated.followUpUserId = userIdSelected.value || userId
      updated.User = user
      // updated.followUpUserId = userData.id
      // updated.followUpCustomerId = customerId
    })
    console.log('custUpdated', custUpdated)
    const result = await DataStore.save(
      custUpdated
    );
    console.log('Result updateFollowUpMutation', result)
    if (result) {
      const readableDate = moment(date).format('DD/MM/YYYY HH:mm')
      const newNote = new History({
        value: -1,
        type: HistoryRecordType.SEGUIMIENTO_ACTUALIZADO,
        description: `Fecha programada: ${readableDate} -
        Estado: ${status.replace('_', ' ')} - 
        Asignado a: ${userIdSelected.label} - 
        Motivo: ${title} - 
        Notas: ${description || 'Sin notas'}`,
        historyCustomerId: customerId,
        historyUserId: userId
      })
      const apiData = await DataStore.save(newNote);
      console.log('createHistoryRecordMutation:', apiData)
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Hecho"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          ¡Seguimiento actualizado correctamente!
        </ReactBSAlert>
      );
      return result
    }
    return undefined
  }

  async function createFollowUpMutation(data) {
    console.log('createFollowUpMutation with', data)
    const { description, title, status, customerId, date } = data
    try {
      const newFU = new FollowUp({
        date: new Date(date).toISOString(),
        status: status,
        reason: title,
        notes: description,
        followUpUserId: userIdSelected.value,
        followUpCustomerId: customerId
      })
      console.log('Nuevo seguimiento', newFU)
      const result = await DataStore.save(
        newFU
      );
      console.log("Seguimiento guardado correctamente!", result);
      await fetchFollowUps()
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Hecho"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          ¡Seguimiento creado correctamente!
        </ReactBSAlert>
      );
      return result
    } catch (error) {
      console.log("Error guardando cliente", error);
      return false
    }
    return true
}

  // async function completeFollowUp() {
  //   const updateFollowUpResult = await updateFollowUpMutation({id: followUpId, _version: followUpVersion})
  //   console.log('[CardFollowUps] Seguimiento actualizado', updateFollowUpResult)
  //   const createRecordResult = await createHistoryRecordMutation({ note: eventDescription, customerId, followUpId })
  //   console.log('[CardFollowUps] Registro de histórico creado', createRecordResult)
  //   setModalAdd(false)
  //   await fetchFollowUps()
  // }

  async function fetchCustomer() {
    const apiData = await DataStore.query(Customer, customerId);
    setCustomer(apiData)
  }

  async function fetchUsers() {
    // const apiData = await API.graphql({ query: listUsers });
    const apiData = await DataStore.query(User);
    console.log('Usuarios ---->', apiData)
    const userList = []
    // apiData.data.listUsers.items.forEach(c => {
    apiData.forEach(c => {
      userList.push(
        {
          value: c.id,
          label: `${c.name} ${c.surname}`
        }
      )
    })
    console.log(userList)
    setUsers(userList);
  }

  async function fetchFollowUps(userId) {
    
    if (!userId) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData && userData.id) {
        userId = userData.id;
      }
    }
    console.log('fetchFollowUps', userId);
    if (userId !== '') {
        let apiHistoryData = []
        if (followUpType === "assigned") {
          apiHistoryData = await DataStore.query(FollowUp, (c) => c.followUpUserId("eq", userId))
        } else if (followUpType === "unassigned") {
          // apiHistoryData = await DataStore.query(FollowUp, (c) => c.followUpUserId("eq", userId) && c.date("notContains", "20"))
          // apiHistoryData = await DataStore.query(FollowUp, (c) => c.date("notContains", "20"))
          if (customerId && customerId !== 'all') {
            console.log('Seguimientos de usuario')
            apiHistoryData = await DataStore.query(FollowUp, (c) => c.and(c => [
              c.date("notContains", "20"),
              c.followUpCustomerId("eq", customerId)
            ]))
          } else if (customerId === 'all') {
            console.log('Los últimos 100 seguimientos')
            apiHistoryData = await DataStore.query(FollowUp, (c) => c.date("notContains", "20"), {
              sort: s => s.createdAt(SortDirection.DESCENDING),
              page: 0,
              limit: 100
            });
          } else {
            console.log('TODOS los seguimientos')
            apiHistoryData = await DataStore.query(FollowUp, (c) => c.date("notContains", "20"), {
              sort: s => s.createdAt(SortDirection.DESCENDING),
              // page: 0,
              // limit: 100
            });
          }
        } else if (followUpType === "customer") {
          apiHistoryData = await DataStore.query(FollowUp, (c) => c.followUpCustomerId("eq", customerId))
        }
    
        console.log('[CardFollowUps] Lista de seguimientos', apiHistoryData.length)
        
        let followUpData = []
        let today = new Date();
        const todayString = today.toISOString().split('T')[0];
        let items = apiHistoryData;
        // if (customerId) {
        //   console.log('Filtramos seguimientos para el cliente', customerId)
        //   items = items.filter(i => (i.Customer && i.Customer.id === customerId));
        // }
        for (const h of items) {
             const {id, date, status, reason, notes, Customer, _version, User} = h
            // console.log("Seguimiento --> ", h)
            // console.log({date, status, title, reason, notes, Customer, _version, _deleted, User});
            if (Customer && Customer?.id) {
              if (["assigned", "customer"].includes(followUpType)) {
                const currentDate = new Date(date);
                if (date && ( (currentDate.getTime() >= today.getTime()) || date.includes(todayString) ) ) {
                  
                  followUpData.push({
                      id,
                      date,
                      time: date.includes(todayString) ? 'hoy' : currentDate.toLocaleDateString(),
                      name: reason,
                      color: (status === 'PENDIENTE' ? 'error' : status === 'FINALIZADO' ? 'success' : 'info'),
                      notes,
                      description: notes,
                      reason,
                      status,
                      customer: Customer.name + " " + (Customer.surname ? Customer.surname : ''),
                      customerId: Customer.id,
                      userId: User.id,
                      user: User.name + " " + (User.surname ? User.surname : '')
                  })
                }
  
              } else {
                
                let time = 'Sin fecha asignada'
                if (!customerId || customerId === 'all' || (customerId && customerId === Customer.id)) {
                  followUpData.push({
                    id,
                    date,
                    time,
                    name: reason,
                    reason,
                    color: (status === 'PENDIENTE' ? 'error' : status === 'FINALIZADO' ? 'success' : 'info'),
                    notes,
                    description: notes,
                    status,
                    customer: Customer && Customer.name + " " + (Customer.surname ? Customer.surname : ''),
                    customerId: Customer && Customer.id,
                    _version,
                    userId: User && User.id ? User.id : '',
                    user: User && User.name ? User.name + " " + (User.surname ? User.surname : '') : '',
                  })
                }
                
                
              }
            }
            
            
        }

        // followUpData.sort(function(a,b){
        //     // Turn your strings into dates, and then subtract them
        //     // to get a value that is either negative, positive, or zero.
        //     return new Date(a.date) - new Date(b.date);
        //   });
        // console.log('Vamos a escribir datos de seguimiento para', followUpType, 'con', followUpData)
        setState(followUpData)
        
    }
    setIsLoading(false)
  }

  // 

  // Time functions

  function getDateByISO(date) {
    const dateText = moment(date).format('YYYY-MM-DD')
    return dateText
  }

  function updateDateByTime(value) {
    const parts = value.split(':')
    let prevDate = new Date().toISOString()
    if (startDate) {
      prevDate = startDate
    }
    const newDate = moment(prevDate).hours(parts[0]).minutes(parts[1]).toISOString()
    setStartDate(newDate)
    setEventTime(getTimeByISO(newDate))
  }

  function getTimeByISO(date) {
    const timeText = moment(date).format('HH:mm')
    return timeText
  }

  function updateOnlyDate(value) {
    const parts = value.split('-')
    let prevDate = new Date().toISOString()
    if (startDate) {
      prevDate = startDate
    }
    const newDate = moment(prevDate).year(parts[0]).month(parts[1]-1).date(parts[2]).toISOString()
    setStartDate(newDate)
    setEventDate(getDateByISO(newDate))
  }

  function openAddDialog() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    let userId = ''
    if (userData && userData.id) {
      userId = userData.id;
    }
    setEventId(undefined);
    setEventDescription('');
    setEventTitle('');
    setStartDate(new Date())
    setEventDate(getDateByISO(new Date().toISOString()))
    setEventTime(getTimeByISO(new Date().toISOString()))
    setCustomerIdSelected({"value": customerId, "label": (customer.name + ' ' + (customer.surname || ''))})
    setUserIdSelected({"value": userId, "label": (userData.name + ' ' + (userData.surname || ''))})
    setRadios('PENDIENTE')
    setEvent({});
    setModalAdd(true);
  }

  function openEditDialog(eventData) {
    console.log(eventData)
    setEventId(eventData.id);
    setEventDescription(eventData.description);
    setEventTitle(eventData.reason);
    setStartDate(eventData.date)
    setEventDate(getDateByISO(eventData.date || new Date().toISOString()))
    setEventTime(getTimeByISO(eventData.date || new Date().toISOString()))
    setCustomerIdSelected({"value": eventData.customerId, "label": eventData.customer})
    setUserIdSelected({"value": eventData.userId, "label": eventData.user})
    setRadios(eventData.status)
    setEvent(eventData);
    setModalChange(true);
  }

  const addNewEvent = async () => {
    // var newEvents = events;
    await createFollowUpMutation(
      {
        title: eventTitle,
        date: startDate,
        description: eventDescription,
        customerId: customerId,
        status: radios
      }
    )
    // newEvents.push(newFollowUpAdded)
    setModalAdd(false);
    // setEvents(newEvents);

    setStartDate(undefined);
    setEventDate(undefined);
    setEventTime(undefined);
    
    setRadios("PENDIENTE");
    // setEventVersion(null);
    setEventTitle("");
  };

  const changeEvent = async () => {
    state.map(async (prop) => {
      console.log(prop, event)
      if (prop.id + "" === eventId + "") {
        setEvent(undefined);
        await updateFollowUpMutation({ 
          id: eventId, 
          date: startDate, 
          status: radios, 
          description: eventDescription, 
          title: eventTitle, 
          customerId: customerIdSelected.value
        })
      } else {
        return prop;
      }
    });
    setModalChange(false);
    // setEvents(newEvents);
    setRadios("PENDIENTE");
    setEventTime(undefined)
    setEventDate(undefined)
    setStartDate(undefined)
    setEventTitle("");
    setCustomerIdSelected({"value": "", "label": ""})
    setEventDescription("");
    setEventId(undefined);
    // setEventVersion(null);
    setEvent(undefined);
  };

  React.useEffect(() => {
    if (customerId && customerId !== 'all') {
      setIsLoading(true)
      console.log('userEffect customerId >>>> fetchCustomer para', customerId, 'y', followUpType)
      fetchCustomer();
      fetchUsers();
    } else if (customerId === "all") {
      setIsLoading(true)
      console.log('userEffect customerId >>>> fetchFollowUps para', customerId, 'y', followUpType)
      fetchFollowUps();
      fetchUsers();
    }
  }, [customerId]);

  React.useEffect(() => {
    if (customer?.id) {
      console.log('userEffect customer >>>> fetchFollowUps para', customerId, 'y', followUpType)
      fetchFollowUps();
    }
  }, [customer]);

  React.useEffect(() => {
    
    const idParam = queryParams.get("id")
    console.log('useEffect location >>>> ', idParam)
    if (idParam) setCustomerId(idParam)
    
  }, [location]);

  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onCreateFollowUp)
    ).subscribe({
        next: async ({ provider, value }) => {
          DataStore.clear().then(() => {
            DataStore.start().then(() => {
              setTimeout(() => {
                fetchFollowUps();
              }, 2000)
            })
          })
          
          console.log({ provider, value })
        },
        error: error => console.warn(error)
    });

    return () => subscription.unsubscribe();
    // const subscription = DataStore.observe(FollowUp).subscribe((fu) => {
    //   const { id } = fu.element
    //   if (id)
    //     fetchFollowUps()
    // });

    // return () => subscription.unsubscribe();
  })

  return (
    <>
      {alert}
      <Card classes={{ root: classes.cardRoot }} elevation={0}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box
                    component={Event}
                    marginRight=".5em!important"
                  >
                  </Box>
                  {followUpType === "assigned" 
                      ? "Próximos seguimientos" : 
                      (followUpType === "customer") ? "Seguimientos programados" : "Seguimientos sin programar"}: {(customerId === "all" && followUpType === 'unassigned') ? "últimos "+state.length+' registros' : ""+state.length}{}
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box
                  justifyContent="flex-end"
                  display="flex"
                  flexWrap="wrap"
                >
                {((followUpType === "customer") ?
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      openAddDialog()
                    }}
                  >
                  <Box
                    component={Add}
                    marginRight=".2em!important"
                  >
                  </Box>
                    Nuevo
                  </Button> : ""
                )}
                  {/* <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      // setCustomerView(prop.customerId)
                      setFollowUpsView()
                    }}
                  >
                    Ver todos
                  </Button> */}
                </Box>
              </Grid>
            </Grid>
          }
          subheaderTypographyProps={{
            component: Box,
            variant: "h3",
            marginBottom: "0!important",
            color: "initial",
          }}
        ></CardHeader>
        <CardContent classes={{ root: classes.historyContent }}>
          {
            isLoading ? <List disablePadding><ListItem key="cargando..." className={classes.listItemRoot}>Cargando datos...</ListItem></List> :
            <List disablePadding>
            {state.map((prop, key) => {
              return (
                <ListItem key={key} className={classes.listItemRoot}>
                  <Grid container component={Box} alignItems="center">
                    <Grid
                      item
                      className={clsx(
                        classes.gridItemRoot,
                        classes[prop.color + "GridItemRoot"]
                      )}
                    >
                      <Typography
                        variant="h5"
                        component="h5"
                        className={clsx(classes.mb0, {
                          // [classes.lineThrough]: prop.checked,
                        })}
                      >
                        {prop.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={clsx(classes.mb0, {
                          // [classes.lineThrough]: prop.checked,
                        })}
                      >
                        {prop.description}
                      </Typography>
                      <Box
                        fontSize="80%"
                        fontWeight="400"
                        component="small"
                        className={clsx({
                          // [classes.lineThrough]: prop.checked,
                        })}
                      >
                        {prop.time} - {prop.customer}
                      </Box>
                      {
                        (followUpType != "customer" ? 
                          "" :
                          <Box
                            fontSize="80%"
                            fontWeight="400"
                            component="small"
                            className={clsx({
                              // [classes.lineThrough]: prop.checked,
                            })}
                          >
                          <br></br>
                            Gestor: {prop.user}
                          </Box>
                        )
                      }
                      
                    </Grid>
                    <Grid item xs="auto">
                      {(followUpType === "customer" ? '': 
                        <Tooltip title="Ver cliente">
                          <IconButton aria-label="edit"
                                onClick={() => {
                                  setCustomerView(prop.customerId)
                                }
                              }
                            >
                            <Box
                              component={Person}
                              width="1.25rem!important"
                              height="1.25rem!important"
                              color="primary"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Actualizar seguimiento">
                        <IconButton aria-label="edit"
                              onClick={() => {
                                // setFollowUpVersion(prop._version)
                                setCustomerId(prop.customerId)
                                // setFollowUpId(prop.id)
                                // setModalAdd(true)
                                openEditDialog(prop)
                              }
                            }
                          >
                          <Box
                            component={Create}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            color="primary"
                          />
                        </IconButton>
                      </Tooltip>
                      {/* <Checkbox
                        checked={prop.checked}
                        onChange={() => {
                          let newState = state.map((stateProp, stateKey) => {
                            if (key === stateKey) {
                              return {
                                key: stateKey,
                                ...stateProp,
                                checked: !prop.checked,
                              };
                            }
                            return stateProp;
                          });
                          setState(newState);
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        classes={{ checked: classes[prop.color + "Checkbox"] }}
                      /> */}
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
            </List>
          }
        </CardContent>
      </Card>

      {/* <Dialog
        open={modalAdd}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModalAdd(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          
          <FormGroup>
            <FormLabel>Completar un seguimiento</FormLabel>
          </FormGroup>
          
          <FormGroup>
            <FormLabel>Notas</FormLabel>
            <FilledInput
              placeholder="Notas para el seguimiento"
              type="text"
              multiline
              rows="6"
              value={eventDescription}
              onChange={(e) => setEventDescription(e.target.value)}
            />
          </FormGroup>
          
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box>
              <Button color="primary" variant="contained" onClick={completeFollowUp}>
                Completar
              </Button>
            </Box>
            <Button onClick={() => setModalAdd(false)} color="primary">
              Cancelar
            </Button>
          </Box>
        </DialogActions>
      </Dialog> */}

      {/* Edit dialog */}
      <Dialog
        open={modalChange}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModalChange(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <FormGroup>
              <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  Seguimiento con: {customerIdSelected.label}
                </Box>
              
          </FormGroup>
          <FormGroup>
            <FormLabel>Razón del seguimiento</FormLabel>
            <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  {eventTitle}
                </Box>
            {/* <FilledInput
              placeholder="Razón"
              type="text"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
            /> */}
          </FormGroup>
          <Box
            component={Divider}
            marginTop="1rem!important"
            marginBottom="1rem!important"
          />
          <FormGroup>
            {/* <FormLabel>Gestor asignado</FormLabel> */}
            <Autocomplete
              id="combo-box-demo"
              value={userIdSelected}
              onChange={(e, value) => {
                setUserIdSelected(value)
                console.log('gestor seleccionado', value)
              }}
              options={users}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.id === value.id}
              closeIcon={null}
              popupIcon={
                <Box
                  component={KeyboardArrowDown}
                  width="1.25rem!important"
                  height="1.25rem!important"
                />
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Gestor asignado"
                  variant="outlined"
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Grid container>
              <Grid item xs={6}>
                <FormLabel>Fecha</FormLabel>
                <OutlinedInput
                      fullWidth
                      value={eventDate || ""}
                      onChange={(e) => updateOnlyDate(e.target.value)}
                      type="date"
                    />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Hora</FormLabel>
                <OutlinedInput
                      fullWidth
                      type="time"
                      value={eventTime || ""}
                      onChange={(e) => updateDateByTime(e.target.value)}
                    />
              </Grid>
            </Grid>
            
            
          </FormGroup>
          <FormGroup>
            <FormLabel>Estado del seguimiento</FormLabel>
            <Box>
              <Box display="inline-block" marginRight=".5rem">
                <Button
                  variant={radios === "PENDIENTE" ? "contained" : "outlined"}
                  classes={{
                    root: clsx(
                      radios === "PENDIENTE" ? classes.buttonContainedError : classes.buttonOutlineError
                    ),
                  }}
                  onClick={() => setRadios("PENDIENTE")}
                >
                  {radios === "PENDIENTE" && (
                    <Box
                      width="1.25rem!important"
                      height="1.25rem!important"
                      component={Check}
                    />
                  )} Pendiente
                </Button>
              </Box>
              <Box display="inline-block" marginRight=".5rem">
                <Button
                  variant={radios === "EN_PROGRESO" ? "contained" : "outlined"}
                  classes={{
                    root: clsx(
                      radios === "EN_PROGRESO" ? classes.buttonContainedInfo : classes.buttonOutlineInfo
                    ),
                  }}
                  onClick={() => setRadios("EN_PROGRESO")}
                >
                  {radios === "EN_PROGRESO" && (
                    <Box
                      width="1.25rem!important"
                      height="1.25rem!important"
                      component={Check}
                    />
                  )} En progreso
                </Button>
              </Box>
              <Box display="inline-block" marginRight=".5rem">
                <Button
                  variant={radios === "FINALIZADO" ? "contained" : "outlined"}
                  classes={{
                    root: clsx(
                      radios === "FINALIZADO" ? classes.buttonContainedSuccess : classes.buttonOutlineSuccess
                    ),
                  }}
                  onClick={() => setRadios("FINALIZADO")}
                >
                  {radios === "FINALIZADO" && (
                    <Box
                      width="1.25rem!important"
                      height="1.25rem!important"
                      component={Check}
                    />
                  )} Finalizado
                </Button>
              </Box>
              
            </Box>
          </FormGroup>
          <FormGroup>
            <FormLabel>Notas</FormLabel>
            <FilledInput
              placeholder="Notas para el seguimiento"
              type="text"
              multiline
              rows="4"
              value={eventDescription || ""}
              onChange={(e) => setEventDescription(e.target.value)}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box>
              <Box display="inline-block" marginRight=".5rem">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={changeEvent}
                >
                  Actualizar
                </Button>
              </Box>
              {/* <Button
                classes={{
                  root: classes.buttonContainedError,
                }}
                variant="contained"
                onClick={() => {
                  setModalChange(false);
                  deleteEventSweetAlert();
                }}
              >
                Borrar
              </Button> */}
            </Box>
            <Button onClick={() => setModalChange(false)} color="primary">
              Cerrar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Add dialog */}

      <Dialog
        open={modalAdd}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModalAdd(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <FormGroup>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  Seguimiento con: {customer ? customer.name + ' ' + (customer.surname || '') : ''}
                </Box>
          </FormGroup>
          <FormGroup>
            <FormLabel>Razón del seguimiento</FormLabel>
            <FilledInput
              placeholder="Razón"
              type="text"
              value={eventTitle || ""}
              onChange={(e) => setEventTitle(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            {/* <FormLabel>Gestor asignado</FormLabel> */}
            <Autocomplete
              id="combo-box-demo"
              value={userIdSelected}
              onChange={(e, value) => {
                setUserIdSelected(value)
                console.log('gestor seleccionado', value)
              }}
              options={users}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.id === value.id}
              closeIcon={null}
              popupIcon={
                <Box
                  component={KeyboardArrowDown}
                  width="1.25rem!important"
                  height="1.25rem!important"
                />
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Gestor asignado"
                  variant="outlined"
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Grid container>
              <Grid item xs={6}>
                <FormLabel>Fecha</FormLabel>
                <OutlinedInput
                      fullWidth
                      value={eventDate || ""}
                      onChange={(e) => updateOnlyDate(e.target.value)}
                      type="date"
                    />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Hora</FormLabel>
                <OutlinedInput
                      fullWidth
                      type="time"
                      value={eventTime || ""}
                      onChange={(e) => updateDateByTime(e.target.value)}
                    />
              </Grid>
            </Grid>
            
            
          </FormGroup>
          <FormGroup>
            <FormLabel>Estado del seguimiento</FormLabel>
            <Box>
              <Box display="inline-block" marginRight=".5rem">
                <Button
                  variant={radios === "PENDIENTE" ? "contained" : "outlined"}
                  classes={{
                    root: clsx(
                      radios === "PENDIENTE" ? classes.buttonContainedError : classes.buttonOutlineError
                    ),
                  }}
                  onClick={() => setRadios("PENDIENTE")}
                >
                  {radios === "PENDIENTE" && (
                    <Box
                      width="1.25rem!important"
                      height="1.25rem!important"
                      component={Check}
                    />
                  )} Pendiente
                </Button>
              </Box>
              <Box display="inline-block" marginRight=".5rem">
                <Button
                  variant={radios === "EN_PROGRESO" ? "contained" : "outlined"}
                  classes={{
                    root: clsx(
                      radios === "EN_PROGRESO" ? classes.buttonContainedInfo : classes.buttonOutlineInfo
                    ),
                  }}
                  onClick={() => setRadios("EN_PROGRESO")}
                >
                  {radios === "EN_PROGRESO" && (
                    <Box
                      width="1.25rem!important"
                      height="1.25rem!important"
                      component={Check}
                    />
                  )} En progreso
                </Button>
              </Box>
              <Box display="inline-block" marginRight=".5rem">
                <Button
                  variant={radios === "FINALIZADO" ? "contained" : "outlined"}
                  classes={{
                    root: clsx(
                      radios === "FINALIZADO" ? classes.buttonContainedSuccess : classes.buttonOutlineSuccess
                    ),
                  }}
                  onClick={() => setRadios("FINALIZADO")}
                >
                  {radios === "FINALIZADO" && (
                    <Box
                      width="1.25rem!important"
                      height="1.25rem!important"
                      component={Check}
                    />
                  )} Finalizado
                </Button>
              </Box>
              
            </Box>
          </FormGroup>
          
          <FormGroup>
            <FormLabel>Notas</FormLabel>
            <FilledInput
              placeholder="Notas para el seguimiento"
              type="text"
              multiline
              rows="4"
              value={eventDescription || ""}
              onChange={(e) => setEventDescription(e.target.value)}
            />
          </FormGroup>
          
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box>
              <Button color="primary" variant="contained" onClick={addNewEvent}>
                Añadir
              </Button>
            </Box>
            <Button onClick={() => setModalAdd(false)} color="primary">
              Cerrar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

    </>
  );
}

export default CardFollowUps;
