/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      name
      surname
      email
      telephone
      customerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        email
        telephone
        customerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        surname
        email
        telephone
        customerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFollowUp = /* GraphQL */ `
  query GetFollowUp($id: ID!) {
    getFollowUp(id: $id) {
      id
      date
      User {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      reason
      notes
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      followUpUserId
      followUpCustomerId
    }
  }
`;
export const listFollowUps = /* GraphQL */ `
  query ListFollowUps(
    $filter: ModelFollowUpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowUps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        User {
          id
          name
          email
          surname
          telephone
          profile
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Customer {
          id
          email
          name
          telephone
          surname
          alias
          NIF
          birthdate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reason
        notes
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        followUpUserId
        followUpCustomerId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFollowUps = /* GraphQL */ `
  query SyncFollowUps(
    $filter: ModelFollowUpFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFollowUps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        User {
          id
          name
          email
          surname
          telephone
          profile
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Customer {
          id
          email
          name
          telephone
          surname
          alias
          NIF
          birthdate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reason
        notes
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        followUpUserId
        followUpCustomerId
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      surname
      telephone
      profile
      Customers {
        items {
          id
          userID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      value
      type
      description
      Customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Tip {
        id
        title
        description
        category
        premessage
        question
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      FollowUp {
        id
        date
        User {
          id
          name
          email
          surname
          telephone
          profile
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Customer {
          id
          email
          name
          telephone
          surname
          alias
          NIF
          birthdate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reason
        notes
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        followUpUserId
        followUpCustomerId
      }
      User {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      historyCustomerId
      historyTipId
      historyFollowUpId
      historyUserId
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        type
        description
        Customer {
          id
          email
          name
          telephone
          surname
          alias
          NIF
          birthdate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Tip {
          id
          title
          description
          category
          premessage
          question
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        FollowUp {
          id
          date
          reason
          notes
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          followUpUserId
          followUpCustomerId
        }
        User {
          id
          name
          email
          surname
          telephone
          profile
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        historyCustomerId
        historyTipId
        historyFollowUpId
        historyUserId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHistories = /* GraphQL */ `
  query SyncHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        value
        type
        description
        Customer {
          id
          email
          name
          telephone
          surname
          alias
          NIF
          birthdate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Tip {
          id
          title
          description
          category
          premessage
          question
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        FollowUp {
          id
          date
          reason
          notes
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          followUpUserId
          followUpCustomerId
        }
        User {
          id
          name
          email
          surname
          telephone
          profile
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        historyCustomerId
        historyTipId
        historyFollowUpId
        historyUserId
      }
      nextToken
      startedAt
    }
  }
`;
export const getTip = /* GraphQL */ `
  query GetTip($id: ID!) {
    getTip(id: $id) {
      id
      title
      description
      category
      premessage
      question
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTips = /* GraphQL */ `
  query ListTips(
    $filter: ModelTipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        category
        premessage
        question
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTips = /* GraphQL */ `
  query SyncTips(
    $filter: ModelTipFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTips(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        category
        premessage
        question
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      email
      name
      telephone
      surname
      alias
      NIF
      birthdate
      users {
        items {
          id
          userID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contacts {
        items {
          id
          name
          surname
          email
          telephone
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserCustomer = /* GraphQL */ `
  query GetUserCustomer($id: ID!) {
    getUserCustomer(id: $id) {
      id
      userID
      customerID
      user {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserCustomers = /* GraphQL */ `
  query ListUserCustomers(
    $filter: ModelUserCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        customerID
        user {
          id
          name
          email
          surname
          telephone
          profile
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customer {
          id
          email
          name
          telephone
          surname
          alias
          NIF
          birthdate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserCustomers = /* GraphQL */ `
  query SyncUserCustomers(
    $filter: ModelUserCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        customerID
        user {
          id
          name
          email
          surname
          telephone
          profile
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customer {
          id
          email
          name
          telephone
          surname
          alias
          NIF
          birthdate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
