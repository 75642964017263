import React from "react";
import { useLocation } from "react-router-dom";
// react plugin used to create datetimepicker
// import ReactDatetime from "react-datetime";
import moment from "moment";
import "moment/locale/es";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
// import Badge from "@material-ui/core/Badge";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
// import Divider from "@material-ui/core/Divider";
// import FormControl from "@material-ui/core/FormControl";
// import FormGroup from "@material-ui/core/FormGroup";
// import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Typography from "@material-ui/core/Typography";
// import TextField from "@material-ui/core/TextField";
// @material-ui/lab components
// import Autocomplete from "@material-ui/lab/Autocomplete";
// @material-ui/icons components
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import Flight from "@material-ui/icons/Flight";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
// import SentimentNeutralIcon from '@material-ui/icons/SentimentNeutral';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
// import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// import TrendingUp from "@material-ui/icons/TrendingUp";
// import Code from "@material-ui/icons/Code";
// import Notifications from "@material-ui/icons/Notifications";
// import ThumbUp from "@material-ui/icons/ThumbUp";
// import Email from "@material-ui/icons/Email";
// import Person from "@material-ui/icons/Person";
// import Public from "@material-ui/icons/Public";
import Call from "@material-ui/icons/Call";
// import DateRange from "@material-ui/icons/DateRange";
import Notifications from "@material-ui/icons/Notifications";
import ThumbUp from "@material-ui/icons/ThumbUp";
import ThumbDown from "@material-ui/icons/ThumbDown";
import AssistantIcon from '@material-ui/icons/Assistant';
// core components
import CustomerHeader from "components/Headers/CustomerHeader.js";
// import CardProfile from "components/Cards/Profile/CardProfile.js";
// import CardStatsGradient from "components/Cards/Widgets/CardStatsGradient.js";
import CardStats from "components/Cards/Dashboard/CardStats.js";
// import CardProgressTrack from "components/Cards/Dashboard/CardProgressTrack.js";
import CardFollowUps from "components/Cards/Dashboard/CardFollowUps.js";
import CardCalls from "components/Cards/Dashboard/CardCalls.js";

import componentStyles from "assets/theme/views/admin/profile.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";
import componentStylesTimeline from "assets/theme/views/admin/timeline.js";
import componentStylesBadge from "assets/theme/components/badge.js";

// Amplify
// import { API } from 'aws-amplify';
// import { getCustomer, listHistories } from "graphql/queries";
import CustomerTimeline from "./CustomerTimeline";
// import CustomerValuesChart from "./CustomerValuesChart";
// import { listUsers } from "graphql/queries";
// import { listHistories } from "graphql/queries";
import { DataStore } from "@aws-amplify/datastore";
// import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../../graphql/subscriptions';
import { Customer, FollowUp, History, Status, User } from "../../../models";
import Event from "@material-ui/icons/Event";
import Check from "@material-ui/icons/Check";

// Styles
const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);
const useStylesTimeline = makeStyles(componentStylesTimeline);
const useStylesBadge = makeStyles(componentStylesBadge);

const customStyles = makeStyles(({
  cardStatsCustom: {
    marginBottom: '1.5rem!important',
  },
  historyContent: {
    overflow: 'auto',
    maxHeight: '600px'
  }  
}))


function CustomerView(props) {

  const location = useLocation();
  const queryParams = new URLSearchParams(props.location.search);
  const [customerId, setCustomerId] = React.useState('');
  const [customer, setCustomer] = React.useState({});
  const [history, setHistory] = React.useState([]);
  
  const [users, setUsers] = React.useState([]);

  const [valueMed, setValueMed] = React.useState(0)
  const [intNum, setIntNum] = React.useState(0)

  const [fuCompleted, setFuCompleted] = React.useState(0)
  const [fuTotal, setFuTotal] = React.useState(0)
  
  

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    console.log('CustomerView', props)

    setCustomerId(queryParams.get("id"));
    console.log('customerId', customerId);

  }, [location]);

  React.useEffect(() => {
    if (customerId && customerId.length > 0) {
      fetchCustomer(customerId);
      fetchHistory(customerId);
      fetchUsers()
    }
  }, [customerId])

  React.useEffect(() => {
    if (users && users.length > 0 
      && customer.users && customer.users.items 
      && customer.users.items[0]) {
      const {userID} = customer.users.items[0]
      const currentUserData = users.find(u => u.value === userID)
      if (currentUserData) {
        console.log(currentUserData)
        setUserIdSelected(currentUserData)
      }
    }
    if (customer.Contacts && customer.Contacts.items) {
      const contact = customer.Contacts.items[0]
      if (contact && contact.name) {
        setCustomerContact(contact)
      }
    }
  }, [customer, users])

  React.useEffect(() => {
    fetchFollowUps()
    // let timer1 = setInterval(() => fetchHistory(customerId), delay * 1000);
    // let timer1 = setInterval(async () => {await DataStore.start(); fetchHistory()}, delay * 1000);
    // return () => {
    //   clearInterval(timer1);
    // };

    // const subscription = DataStore.observe(History).subscribe((h) => {
    //   console.log('[SUBSCRIPTION] Subscription on history', h)
    //   fetchHistory(customerId)
    // });
  }, [customerId])

  React.useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onCreateHistory)
    ).subscribe({
        next: async ({ provider, value }) => {
          DataStore.clear().then(() => {
            DataStore.start().then(() => {
              setTimeout(() => {
                fetchHistory(customerId)
              }, 2000)
            })
          })
          
          console.log({ provider, value })
        },
        error: error => console.warn(error)
    });

    return () => subscription.unsubscribe();
  })

  React.useEffect(() => {
    // const subscription = DataStore.observe(History).subscribe((fu) => {
    //   console.log('Subscription result', fu)
    //   const { id } = fu.element
    //   // if (id)
    //     fetchHistory(customerId)
    // });

    // return () => subscription.unsubscribe();
  })

  const getIconByValue = (value, type) => {
    if ('SOLICITUD_LLAMADA' === type) {
      return Notifications
    }
    if ('LLAMADA_REALIZADA' === type) {
      return Call
    }
    if ('SEGUIMIENTO_ACTUALIZADO' === type) {
      return Event
    }
    if ('INICIO_SESION' === type) {
      return AssistantIcon
    }
    let statusIcons = [
      SentimentVeryDissatisfiedIcon,
      SentimentDissatisfiedIcon,
      // SentimentNeutralIcon,
      SentimentSatisfiedIcon,
      SentimentVerySatisfiedIcon
    ]
    let adviceIcons = [
      ThumbDown,
      ThumbDown,
      ThumbUp,
      ThumbUp
    ]
    let icons = 'ESTADO' === type ? statusIcons : adviceIcons
    if (value <= 3) {
      return icons[0]
    } else if (value > 3 && value < 5) {
      return icons[1]
    } else if (value >= 5 && value < 7) {
      return icons[2]
    } else {
      return icons[3]
    }
  }

  const getColorByValue = (value, isBadge) => {
    let colorsNot = [
      'Error',
      'Warning',
      'Info',
      'Success'
    ]
    let colorsBadge = [
      "error","default","primary","secondary"
    ]
    let colors = isBadge ? colorsBadge : colorsNot
    if (value <= 3) {
      return colors[0]
    } else if (value > 3 && value < 5) {
      return colors[1]
    } else if (value >= 5 && value < 7) {
      return colors[2]
    } else {
      return colors[3]
    }
  }

  async function fetchCustomer(customerId) {
    console.log("Customer ID", customerId);
    const apiData = await DataStore.query(Customer, customerId);
    // const apiData = await API.graphql({ query: getCustomer, variables: {id: customerId} });
    // const customerData = apiData.data.getCustomer;
    const customerData = apiData;
    setCustomer(customerData);
    // setCustomerName(customerData.name);
    // setCustomerSurname(customerData.surname);
    // setCustomerOcasoId(customerData.ocasoId);
    // const bd = new Date(customerData.birthdate)
    // setBirthdate(bd)
    // console.log('Customer:', customer);
    return customerData;
  }

  async function fetchUsers() {
    // const apiData = await API.graphql({ query: listUsers });
    const apiData = await DataStore.query(User);
    const userList = []
    // apiData.data.listUsers.items.forEach(c => {
    apiData.forEach(c => {
      userList.push(
        {
          value: c.id,
          label: `${c.name} ${c.surname}`
        }
      )
    })
    console.log(userList)
    setUsers(userList);
  }

  async function fetchFollowUps() {
    const followUpsForCustomer = await DataStore.query(FollowUp, f => f.followUpCustomerId("eq", customerId))
    setFuTotal(followUpsForCustomer.length)
    let completed = 0
    for (const fu of followUpsForCustomer) {
      if (Status.FINALIZADO === fu.status ) {
        completed++
      }
    }
    setFuCompleted(completed)
  }

  async function fetchHistory(customerId) {
      console.log('fetchHistory', customerId)
      const thirtyDaysAgo = moment().subtract(30, 'days').format()
      console.log('thirtyDaysAgo', thirtyDaysAgo)
      if (customerId !== '') {
          const apiHistoryData = await DataStore.query(History, (c) => c.historyCustomerId("eq", customerId))
          const historyData = []
          const titles = {
              'ESTADO': 'Estado del día',
              'SOLICITUD_LLAMADA': 'Solicita una llamada',
              'CONSEJO': 'Recibe un consejo',
              'LLAMADA_REALIZADA': 'Se ha realizado una llamada al cliente',
              'SEGUIMIENTO_ACTUALIZADO': 'Seguimiento actualizado',
              'INICIO_SESION': 'Acceso a Alexa Skill Ocaso'
          }
          let values = []
          
          apiHistoryData.forEach( h => {
              const {type, description, value, createdAt, id, Customer} = h
              console.log({type, description, value})
              if (type === 'ESTADO') {
                values.push(value)
              }
              historyData.push({
                  id,
                  createdAt,
                  time: moment(createdAt).fromNow(),
                  datetime: new Date(createdAt).toLocaleString(),
                  title: titles[type],
                  type,
                  description,
                  name: description,
                  value,
                  color: ['LLAMADA_REALIZADA', 'SEGUIMIENTO_ACTUALIZADO', 'INICIO_SESION'].includes(type) ? 'info' : getColorByValue(value),
                  colorBadge: ['LLAMADA_REALIZADA', 'SEGUIMIENTO_ACTUALIZADO', 'INICIO_SESION'].includes(type) ? 'info' : getColorByValue(value, true),
                  // "default","error","primary","secondary"
                  customer: Customer.name + " " + Customer.surname,
                  customerId: Customer.id,
                  icon: getIconByValue(value, type)
              })
          })
          historyData.sort(function(a,b){
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
          if (values && values.length) {
            let sum = values.reduce((previous, current) => current += previous);
            let avg = sum / values.length;
            setValueMed(Math.round(avg * 100) / 100)
            setIntNum(values.length)
          }
          setHistory(historyData)
      }
  }



  const classes = { ...useStyles(), ...useStylesCardImg(), ...useStylesTimeline(), ...useStylesBadge(), ...customStyles() };

  return (
    <>
      <CustomerHeader customer={customer} history={props.history} view={"view"}/>
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>

          {/* Timeline del histórico */}
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <CardCalls history={props.history} customerId={customer.id} data={history}  />
                <CardFollowUps history={props.history} customerId={customer.id} type={"customer"} />
                <CardFollowUps history={props.history} customerId={customer.id} type={"unassigned"} />
              </Grid>
              <Grid item xs={12} lg={12}>
                
              </Grid>
              
            </Grid>
            
          </Grid>

          {/* Perfil del usuario */}
          <Grid
            item
            xs={12}
            lg={6}
            component={Box}
            marginBottom="3rem"
            paddingBottom="3rem"
            // classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            {/* Métricas de cabecera */}
            <Grid container
              classes={{
                root: classes.cardStatsCustom
              }}>
              <Grid item xs={12} lg={6}>
                <CardStats
                  subtitle="Valoración media"
                  title={(valueMed || 0)+"/10"}
                  icon={getIconByValue(valueMed, 'ESTADO')}
                  color={"bg"+getColorByValue(valueMed)}
                  elevation={1}
                  footer={
                    <>
                      {/* <Box
                        component="span"
                        fontSize=".875rem"
                        color={theme.palette.white.main}
                        marginRight=".5rem"
                        display="flex"
                        alignItems="center"
                      >
                        <Box
                          component={ArrowUpward}
                          width="1.5rem!important"
                          height="1.5rem!important"
                        />{" "}
                        3.48%
                      </Box>
                      <Box
                        component="span"
                        whiteSpace="nowrap"
                        color={theme.palette.gray[400]}
                      >
                        desde el último mes
                      </Box> */}
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CardStats
                  subtitle="Estados recibidos"
                  title={'' + intNum}
                  icon={RecordVoiceOverIcon}
                  color="bgInfo"
                  elevation={1}
                  footer={
                    <>
                      {/* <Box
                        component="span"
                        fontSize=".875rem"
                        color={theme.palette.white.main}
                        marginRight=".5rem"
                        display="flex"
                        alignItems="center"
                      >
                        <Box
                          component={ArrowUpward}
                          width="1.5rem!important"
                          height="1.5rem!important"
                        />{" "}
                        3.48%
                      </Box>
                      <Box
                        component="span"
                        whiteSpace="nowrap"
                        color={theme.palette.gray[400]}
                      >
                        Since last month
                      </Box> */}
                    </>
                  }
                />
              </Grid>
              
            </Grid>

            <Grid container
              classes={{
                root: classes.cardStatsCustom
              }}>
              <Grid item xs={12} lg={12}>
                <CardStats
                  subtitle="Seguimientos completados/totales"
                  title={fuCompleted+"/"+fuTotal}
                  icon={Check}
                  color={"bgSuccess"}
                  elevation={1}
                  footer={
                    <>
                    </>
                  }
                />
              </Grid>
              
              
            </Grid>

            {/* <CustomerValuesChart history={history}/> */}
            <Card elevation={1}>
              <CardHeader
                className={classes.cardHeader}
                title="Histórico"
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0!important",
                  variant: "h3",
                }}
              ></CardHeader>
              <CardContent
                classes={{
                  root: classes.historyContent
                }}
                >
                <CustomerTimeline history={history} />
              </CardContent>
            </Card>

            
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CustomerView;
