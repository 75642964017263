import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Call from "@material-ui/icons/Call";
import Event from "@material-ui/icons/Event";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import EmojiEvents from "@material-ui/icons/EmojiEvents";
import GroupAdd from "@material-ui/icons/GroupAdd";
import Home from "@material-ui/icons/Home";
import InsertChartOutlined from "@material-ui/icons/InsertChartOutlined";
// import PieChart from "@material-ui/icons/PieChart";

// core components
import CardStats from "components/Cards/Dashboard/CardStats.js";

import componentStyles from "assets/theme/components/headers/stats-header.js";

// AMPLIFY
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { FollowUp, Customer, History } from "../../models";

const useStyles = makeStyles(componentStyles);

const DashboardHeader = ({ section, subsection }) => {

  const [customerStats, setCustomerStats] = React.useState(undefined);
  const [interactionStats, setInteractionStats] = React.useState(undefined);
  const [callStats, setCallStats] = React.useState(undefined);
  const [followUpsStats, setFollowUpsStats] = React.useState(undefined);
  const today = (new Date()).toISOString().split('T')[0];

  React.useEffect(() => {
    fetchCustomers();
    fetchFollowUps();
    fetchHistory();
  }, []);
  
  async function fetchCustomers() {
    const apiData = await DataStore.query(Customer, Predicates.ALL)
    setCustomerStats(apiData.length);
  }

  async function fetchHistory() {
    const apiData = await DataStore.query(History, (h) => h.createdAt("contains", today));
    const eventsToday = apiData.length;
    const callsToday = apiData.filter(i => i.type === "SOLICITUD_LLAMADA").length;
    setInteractionStats(eventsToday);
    setCallStats(callsToday);
  }

  async function fetchFollowUps(userId) {
    
    if (!userId) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData && userData.id) {
        userId = userData.id;
      }
    }
    console.log('fetchFollowUps', userId);
    if (userId !== '') {
        const apiHistoryData = await DataStore.query(FollowUp, (c) => c.and(c => [
          c.followUpUserId("eq", userId),
          c.date("contains", today)
        ]))
        
        console.log('Lista de seguimientos', apiHistoryData);

        const eventsToday = apiHistoryData.length;
        setFollowUpsStats(eventsToday);
    }
  }

  const classes = useStyles();
  // const theme = useTheme();
  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <Grid
            container
            component={Box}
            alignItems="center"
            paddingTop="1.5rem"
            paddingBottom="1.5rem"
          >
            <Grid item xs={7} lg={6} className={classes.gridItem}>
              <Typography
                variant="h2"
                component="h6"
                className={clsx(
                  classes.displayInlineBlock,
                  classes.mb0,
                  classes.textWhite
                )}
              >
                {section}
              </Typography>
              <Breadcrumbs
                separator="-"
                aria-label="breadcrumb"
                classes={{
                  root: classes.breadcrumbRoot,
                  li: classes.breadcrumbLi,
                  ol: classes.breadcrumbOl,
                  separator: classes.breadcrumbSeparator,
                }}
              >
                <Link
                  color="inherit"
                  href="/"
                  onClick={(e) => e.preventDefault()}
                >
                  <Box
                    component={Home}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    position="relative"
                  />
                </Link>
                <Link
                  color="inherit"
                  href="/getting-started/installation/"
                  onClick={(e) => e.preventDefault()}
                >
                  {subsection}
                </Link>
                <Typography
                  component="span"
                  className={classes.breadcrumbActive}
                >
                  {section}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={5} lg={6} component={Box} textAlign="right">
              
            </Grid>
          </Grid>

          {/* Stats */}
          <Grid container>
            <Grid item xl={3} lg={6} xs={12}>
              <CardStats
                subtitle="Solicitudes"
                title={'' + (callStats ? callStats : 0)}
                icon={Call}
                color="bgError"
                footer={
                  <>
                    <Box component="span" whiteSpace="nowrap">
                      de llamada hoy
                    </Box>
                  </>
                }
              />
            </Grid>
            
            <Grid item xl={3} lg={6} xs={12}>
              <CardStats
                subtitle="Seguimientos"
                title={'' + (followUpsStats ? followUpsStats : 0)}
                icon={Event}
                color="bgInfo"
                footer={
                  <>
                    <Box component="span" whiteSpace="nowrap">
                      programados para hoy
                    </Box>
                  </>
                }
              />
            </Grid>
            <Grid item xl={3} lg={6} xs={12}>
              <CardStats
                subtitle="Clientes"
                title={'' + (customerStats ? customerStats : 0)}
                icon={GroupAdd}
                color="bgSuccess"
                footer={
                  <>
                    <Box component="span" whiteSpace="nowrap">
                      activos en el sistema
                    </Box>
                  </>
                }
              />
            </Grid>
            <Grid item xl={3} lg={6} xs={12}>
              <CardStats
                subtitle="Interacciones"
                title={'' + (interactionStats ? interactionStats : 0)}
                icon={InsertChartOutlined}
                color="bgWarning"
                footer={
                  <>
                    <Box component="span" whiteSpace="nowrap">
                      hoy en Alexa
                    </Box>
                  </>
                }
              />
            </Grid>
            
          </Grid>
        </Container>
      </div>
    </>
  );
};

DashboardHeader.propTypes = {
  section: PropTypes.string,
  subsection: PropTypes.string
};

export default DashboardHeader;
