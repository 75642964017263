/* eslint-disable react/jsx-no-target-blank*/
import React from "react";
import { useLocation } from "react-router-dom";
// import { useLocation, Link } from "react-router-dom";
// import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import { useTheme } from "@material-ui/core/styles";
// import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
// import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
// import CardContent from "@material-ui/core/CardContent";
// import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Tooltip from "@material-ui/core/Tooltip";
// import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
// import Build from "@material-ui/icons/Build";
// import Code from "@material-ui/icons/Code";
// import Done from "@material-ui/icons/Done";
// import Public from "@material-ui/icons/Public";
// import EmojiEmotions from "@material-ui/icons/EmojiEmotions";
// import Settings from "@material-ui/icons/Settings";
// import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
// core components

import OcasoNavbar from "components/Navbars/OcasoNavbar.js";
import OcasoFooter from "components/Footers/OcasoFooter.js";

// import IndexNavbar from "components/Navbars/IndexNavbar.js";
// import IndexHeader from "components/Headers/IndexHeader.js";
// import CardInfoBadges from "components/Cards/Index/CardInfoBadges.js";


import componentStyles from "assets/theme/views/index.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);


export default function Privacy() {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
//   const theme = useTheme();
  const location = useLocation();
  React.useEffect(() => {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
  }, [location]);
  return (
    <>
      <OcasoNavbar />
      <Box position="relative">
        
        <Box
          paddingTop="6rem"
          paddingBottom="6rem"
          component="section"
          overflow="hidden"
          className={classes.bgWhite}
        >
          <Container maxWidth="xl">
            <Grid container className={classes.justifyContentLeft}>
              <Grid item xs={12} lg={12} className={classes.textLeft}>

              <div>
        <h1>Política de Privacidad DEL SERVICIO DEL ASISTENTE VIRTUAL OCASO o SKILL OCASO.</h1>
        <p><br /><br /></p>
        <h2>Información básica en materia de protección de datos</h2>
        <table border={1} width="100%" cellSpacing={0} cellPadding={10}>
          <tbody>
            <tr>
              <td>
                <p><strong>RESPONSABLE</strong></p>
              </td>
              <td>
                <p>LEGALITAS SALUD SA</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>DATO CONTACTO DPO</strong></p>
              </td>
              <td>
                <p><a href="mailto:dpo@legalitas.es">dpo@legalitas.es</a></p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>FINALIDAD</strong></p>
              </td>
              <td>
                <p>Prestación del servicio de Asistente virtual Ocaso o Skill Ocaso, funcionalidad de ALEXA, asistente virtual desarrollado por AMAZON y controlada por voz, siendo ECHO el dispositivo físico que se utiliza para interactuar con el asistente.</p>
                <p>Las principales finalidades de este servicio son las siguientes:</p>
                <p><strong>Seguimiento diario de síntomas automatizado</strong>: (Valoración 1-10 y aviso en los casos previstos a LEGÁLITAS SALUD y, en su caso, al familiar designado por el usuario a este fin.</p>
                <p><strong>Llamada automatizada de Ocaso mediante dispositivo</strong></p>
                <p><strong>Asistencia de un médico 24 horas</strong>, los 7 días de la semana. Consejo médico, que en ningún caso sustituirá una consulta médica presencial.</p>
                <p><strong>Aviso a persona designada al efecto por el asegurado (puntualmente o notificaciones diarias según la opción elegida)</strong>, mediante SMS o mail, del estado de salud del asegurado.</p>
                <p><strong>Informe semanal de control y seguimiento</strong>, a <strong>persona designada al efecto por el asegurado</strong>, del estado de bienestar del asegurado.</p>
                <p><strong>Servicio de recomendaciones Skill de Alexa </strong></p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>LEGITIMACIÓN</strong></p>
              </td>
              <td>
                <p>· Ejecución de contrato (art. 6.1.b RGPD)</p>
                <p>· Consentimiento del interesado (art. 6.1.a RGPD) y explicito para tratamiento de datos de salud (art. 9.2.a RGPD)</p>
                <p>· Tratamiento de datos necesario para la prestación de asistencia sanitaria (art. 9.2. h RGPD</p>
                <p>· Cumplimiento de las obligaciones legales aplicables (art. 6.1.c RGPD).</p>
                <p>· Interés legítimo (art. 6.1.f RGPD)</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>DESTINATARIOS</strong></p>
              </td>
              <td>
                <ul type="disc">
                  <li>Encargados de tratamiento necesarios para la ejecución de la relación contractual o precontractual.</li>
                  <li>Organismos y Administraciones Públicas en caso de obligación legal</li>
                  <li>Entidades financieras para la gestión de cobros y pagos.</li>
                  <li>Tercero designado por el usuario para recibir información del servicio</li>
                  <li>Servicio de emergencias 112 en los casos previstos para ello</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>TRANSFERENCIAS INTERNACIONALES</strong></p>
              </td>
              <td>
                <p>No se realizan</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>DERECHOS</strong></p>
              </td>
              <td>
                <p>Puede ejercer gratuitamente sus derechos a Acceder, Rectificar, Suprimir, Oposición, Limitación y Olvido en el correo electrónico&nbsp;<a href="mailto:saclopd@legalitas.es">saclopd@legalitas.es</a>&nbsp;o mediante correo postal dirigido a LEGÁLITAS, Av. Leopoldo Calvo-Sotelo Bustelo nº 6 3º 28224 Pozuelo de Alarcón Madrid.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>PERFILADO</strong></p>
              </td>
              <td>
                <p>No se realiza</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>INFORMACIÓN ADICIONAL</strong></p>
              </td>
              <td>
                <p>Consulte la&nbsp;<em>Información Adicional</em>&nbsp;sobre la política de privacidad a continuación</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p><br /><strong>INFORMACION ADICIONAL SOBRE LA </strong><strong>POLITICA DE PROTECCION DE DATOS PERSONALES DEL SERVICIO DE ASISTENTE VIRTUAL OCASO o SKILL OCASO. </strong></p>
        <p>LEGALITAS SALUD, S.A. (en adelante LEGALITAS SALUD), con N.I.F. A-84948215, y domicilio social en Avda. Leopoldo Calvo Sotelo Bustelo, 6, Pozuelo de Alarcón (28224 MADRID), proveedor de OCASO, S.A., COMPAÑÍA DE SEGUROS Y REASEGUROS (en adelante OCASO) actúa como ENCARGADO DEL TRATAMIENTO de OCASO, en virtud del contrato que mantiene con ésta, para ofrecer a los asegurados de OCASO que tengan contratada la garantía de contratación adicional de atención médica a través del asistente virtual Ocaso o Skill Ocaso indistintamente.</p>
        <p><strong>INTRODUCCION</strong></p>
        <p>Esta Política de Protección de Datos se refiere específicamente al servicio del asistente virtual Ocaso o Skill Ocaso con la finalidad de informar a los usuarios del Asistente Virtual o SkillOCASO sobre el tratamiento y protección de sus datos de carácter personal que ellos voluntariamente hayan facilitado, y/o que puedan ser recabados durante el proceso de registro, acceso, navegación y utilización del servicio ya sea a través de su sitio web o de la propia Skill.</p>
        <p>En consecuencia, en lo que respecta a otros servicios o coberturas de los asegurados de OCASO en los que LEGALITAS SALUD es encargado del tratamiento de datos personales, regirá lo dispuesto en las políticas específicas del concreto servicio o cobertura, pudiendo éstas ser complementarias.</p>
        <p><strong>¿Quién es el responsable del tratamiento de sus datos personales?</strong></p>
        <p>En lo referido al servicio de Asistente virtual Ocaso o Skill Ocaso, prestado por LEGALITAS SALUD, ésta actúa como Encargado de Tratamiento de OCASO en lo referente a la solicitud de información y/o asesoramiento en el proceso de registro, navegación y utilización del asistente virtual Ocaso.</p>
        <p>OCASO pone a disposición de LEGALITAS SALUD únicamente los datos identificativos necesarios para confirmación del aseguramiento de la persona que solicita el servicio.</p>
        <p>No obstante lo anterior, una vez confirmado el derecho del solicitante del servicio, LEGALITAS SALUD prestará el servicio en calidad de RESPONSABLE de los tratamientos de datos personales necesarios para su ejecución, de forma independiente, sin seguir instrucciones de OCASO en cuanto al modo de prestarlo, los medios utilizados o los fines del tratamiento.</p>
        <p>En consecuencia, el Responsable de tratamiento de los datos personales que el titular de los datos facilite directamente a LEGALITAS SALUD en el curso de la prestación del servicio será la sociedad LEGALITAS SALUD, S.A. (en adelante LEGALITAS SALUD), con N.I.F. A-84948215, y domicilio social en Avda. Leopoldo Calvo Sotelo Bustelo, 6, Pozuelo de Alarcón (28224 MADRID).</p>
        <p>Para la adecuada gestión del tratamiento de sus datos personales, LEGALITAS SALUD tiene designado un Delegado de Protección de Datos ante el que podrá acudir para resolver cualquier cuestión que precise, pudiendo contactar a través del correo electrónico dpo@legalitas.es.</p>
        <p>En cuanto a la propia Skill, se informa a los interesados que las Skills son funcionalidades de ALEXA controladas por voz. ALEXA es un asistente virtual desarrollado por AMAZON; es un servicio de Inteligencia Artificial (IA) que a través de sus sistemas de reconocimiento de voz y comprensión del lenguaje natural puedan procesar y responder a la petición del usuario.</p>
        <p>Por su parte, ECHO es el dispositivo físico que se utiliza para interactuar con el asistente.</p>
        <p>AMAZON gestiona cualquier información personal que recibe a través de ALEXA de acuerdo a su aviso de privacidad, <a href="https://www.amazon.es/gp/help/customer/display.html?nodeId=201909010">https://www.amazon.es/gp/help/customer/display.html?nodeId=201909010</a>.</p>
        <p>Amazon Europe Core SARL, Amazon EU SARL, Amazon Services Europe SARL y Amazon Media EU SARL, las cuatro con dirección en el número 38 Avenue John F. Kennedy, L-1855, Luxemburgo, y Amazon Digital UK Limited, con dirección en el número 1 de Principal Place, Worship Street, Londres, EC2A 2FA, Reino Unido (de forma conjunta, "Amazon Europa") son responsables del tratamiento de la información personal recabada y tratada a través de los Servicios de Amazon.</p>
        <p>El usuario de esta Skill o Asistente Virtual Ocaso se registrará y validará su identidad en la misma a través de su correo electrónico.</p>
        <p><strong>¿Con qué finalidad trata sus datos personales LEGALITAS SALUD? </strong></p>
        <p>1.- Contacto y registro en el sistema: LEGALITAS SALUD tratará los datos de los solicitantes del servicio facilitados por ellos mismos, en el momento de su registro y/o solicitud del servicio, a fin de verificar su derecho a la utilización del mismo y recoger su consentimiento explícito para el tratamiento de sus datos, y en especial para los datos de salud, para cada una de las finalidades de los tratamiento que implica la prestación de este servicio.</p>
        <p>Este consentimiento será recogido por LEGÁLITAS mediante llamada telefónica que realizará al usuario con carácter previo a su utilización del SKILL, que quedará grabada, siendo éste advertido previamente de la grabación de la llamada.</p>
        <p>En esa misma llamada se le informará, entre otras cuestiones, de los datos básicos o primera capa de esta política de privacidad y de dónde consultarla de forma completa, se le informará sobre la forma de revocar sus consentimientos y de sus derechos de privacidad y cómo ejercerlos, en la forma que se indica más adelante.</p>
        <p>El servicio se prestará a través de ALEXA, el asistente virtual desarrollado por AMAZON, utilizando la voz y activando el servicio con las palabras “Alexa, abre Ocaso”</p>
        <p>Una vez solicitado el servicio de su interés por parte del usuario, la Skill Ocaso, facilitará a AMAZON el contenido de la solicitud para que ALEXA pueda responder convenientemente, pero no enviará en ningún caso, la grabación de la voz del interesado.</p>
        <p>Si la petición debiera ser ejecutada por parte de LEGALITAS SALUD, por la naturaleza de la misma, la skill comunicará a LEGÁLITAS SALUD los datos necesarios que serán tratados por la misma, exclusivamente con la finalidad de desarrollar el servicio solicitado por el usuario, ya que de lo contrario devendría de imposible ejecución. La Skill no almacenará datos de salud ni los comunicará a LEGÁLITAS SALUD. El objeto de comunicación será siempre un código o numeración programado en función del algoritmo correspondiente al servicio de que se trate.</p>
        <p>LEGÁLITAS SALUD registrará en su CRM únicamente los datos correspondientes a la asistencia prestada, evitando registrar datos de salud, en lo posible, del interesado, usando codificaciones siempre que sea posible, salvo aquellos casos en los que por ser necesaria la intervención del 112 o de otros profesionales y sea imprescindible para poder prestar la asistencia.</p>
        <p>Si para la prestación del servicio seleccionado dentro de la SKILL, fuera necesaria la intervención de profesionales de servicios públicos como el 112 o profesionales con los que LEGALITAS SALUD mantiene acuerdos de colaboración, comunicará exclusivamente los datos personales del usuario necesarios para dar cumplimiento a la prestación del servicio así como la información y/o documentación necesaria relacionada con el mismo sin la cual se imposibilitaría la prestación del servicio</p>
        <p>2.- Prestación del servicio.</p>
        <p>Los servicios que LEGALITAS SALUD prestará al usuario de la Skill Ocaso a través de Alexa son los siguientes:</p>
        <p>o <strong>Seguimiento diario de síntomas automatizado</strong>: Algoritmo de intervención con las valoraciones de estado de salud de 1-10 del asegurado, siendo 10 el mejor y 1 el más bajo.</p>
        
        <p>Si la respuesta es menor o igual de 3 la skill enviará aviso a LEGÁLITAS SALUD y, en su caso, al familiar designado por el usuario a este fin.</p>
        <p>o <strong>Llamada automatizada de Ocaso mediante dispositivo</strong> si la valoración del estado de salud es deficiente o baja (menor o igual de 3) o para las conversaciones objeto de la prestación del servicio que requieren la llamada de LEGÁLITAS SALUD <strong>Asistencia de un médico 24 horas</strong>, los 7 días de la semana. Consejo médico, que en ningún caso sustituirá una consulta médica presencial.</p>
        <p>o <strong>Aviso a persona designada al efecto por el asegurado (puntualmente o notificaciones diarias según la opción elegida)</strong>, mediante SMS o mail, del estado de salud del asegurado.</p>
        <p>o <strong>Informe semanal de control y seguimiento</strong>, a <strong>persona designada al efecto por el asegurado</strong>, del estado de bienestar del asegurado.</p>
        <p>Además de lo anterior, el asegurado tendrá a su<strong> disposición el servicio de recomendaciones Skill</strong> (música relajante, noticias, radio, juegos que ejerciten la memoria, evangelio y mucho más) de acuerdo con la política de privacidad de amazon para Alexa disponible en <a href="https://www.amazon.es/gp/help/customer/display.html?nodeId=201909010">https://www.amazon.es/gp/help/customer/display.html?nodeId=201909010</a>, que permite al usuario obtener, cada vez que utiliza la skill de Ocaso, sugerencias o que se le muestran otras skills disponibles en Alexa.</p>
        <p><strong>¿Qué datos tratamos y de qué fuente se obtienen?</strong></p>
        <p>Los datos tratados provienen de:</p>
        <p>I.- OCASO. La aseguradora OCASO pone a disposición de LEGALITAS SALUD, en cuanto encargado del servicio, únicamente los datos identificativos necesarios para confirmación del derecho al uso del servicio de la persona que lo solicita.</p>
        <p>II.-El propio asegurado.</p>
        <p>· Una vez LEGALITAS SALUD haya comprobado con OCASO su derecho a utilizar el servicio solicitado, el usuario facilitará a LEGALITAS SALUD los datos personales que ésta necesite para gestionar la prestación del servicio, en la forma indicada en esta política.</p>
        <p>· Datos necesarios para la propia gestión, mantenimiento y desarrollo de la relación contractual, tales como tipo de prestaciones solicitadas, incidencias, contactos emitidos o recibidos, etc.</p>
        <p>En función del servicio solicitado, los datos personales objeto de tratamiento pueden pertenecer a las siguientes categorías:</p>
        <p>o Datos identificativos (nombre, apellidos, documento de identidad, dirección postal, correo electrónico, número de teléfono, ip).</p>
        <p>o Datos derivados de las prestaciones solicitadas por el interesado que pueden incluir datos de salud o de geolocalización</p>
        <p>El Usuario de LEGÁLITAS SALUD garantiza que los datos aportados son verdaderos, exactos, completos y actualizados, siendo responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación.</p>
        <p>En el caso de que los datos aportados pertenecieran a un tercero, el Usuario, garantiza que ha informado a dicho tercero de los aspectos contenidos en esta cláusula y obtenido su autorización explícita para facilitar sus datos a LEGÁLITAS SALUD para prestar el servicio, o de quien deba prestarlo en su nombre en caso de que este sea menor de 16 años o incapaz.</p>
        <p><strong>¿Cuál es la legitimación legal para el tratamiento de sus datos?</strong></p>
        <p>El tratamiento de sus datos a través de la Skill Ocaso y al registro en los sistemas de LEGALITAS SALUD están basados en el registro, tramitación y mantenimiento de la solicitud que realiza el asegurado de OCASO y de la propia prestación de los servicios por parte de LEGALITAS SALUD.</p>
        <p>De igual forma, la base legal para el tratamiento de sus datos derivados de la “prestación de los servicios”, es la ejecución de la garantía que el asegurado tiene contratada con OCASO, referente a la Skill Ocaso y en la propia ejecución del contrato de LEGALITAS SALUD como proveedor de OCASO.</p>
        <p>Los datos personales aportados por los usuarios serán incorporados a un Registro de Tratamientos titularidad de LEGÁLITAS SALUD y serán tratados con la finalidad principal de atender las solicitudes relacionadas con la prestación de servicios y cumplimiento de obligaciones contractuales y precontractuales.</p>
        <p>A continuación se detallan estas finalidades, y la base legal o LEGITIMACION que habilita a LEGÁLITAS SALUD al tratamiento de sus datos para ello.</p>
        <p><strong>Por ser un tratamiento necesario para la ejecución de contrato en el que el interesado es parte (art. 6.1.b RGPD)</strong></p>
        <ul type="disc">
          <li>Prestación del servicio solicitado a través del Asistente Virtual OCASO, si bien el tratamiento de los datos de salud tendrán base en el consentimiento explícito.</li>
          <li>Cesión a colaboradores de LEGÁLITAS SALUD de sus datos personales, cuando así sea solicitado para el cliente como parte del servicio contratado</li>
          <li>Cesión al servicio público 112 en caso de ser necesaria su intervención</li>
          <li>Gestión de los sistemas de LEGÁLITAS necesarios para la prestación del servicio, lo que incluye procesos tales como el registro de los expedientes en el CRM, gestión de copias de seguridad o backups, así como tareas de mantenimiento, administración y monitorización de los sistemas, bloqueo y anonimización de datos de clientes. Los correos electrónicos podrán reportar una confirmación de su recepción y lectura.</li>
        </ul>
        <p>Se informa a los interesados de que estos tratamientos, en ejecución del&nbsp;<strong>contrato pueden incluir categorías especiales de datos personales.</strong></p>
        <p><strong>¿Qué son las categorías especiales de datos y por qué LEGÁLITAS SALUD puede tratarlos?</strong></p>
        <p>Se trata de datos con una protección especial, están definidas en el artículo 9 del RGPD y son las siguientes: datos que revelen el origen étnico o racial, las opiniones políticas, las convicciones religiosas o filosóficas, o la afiliación sindical, y el tratamiento de datos genéticos, datos biométricos dirigidos a identificar de manera unívoca a una persona, datos relativos a la salud o datos relativo a la vida sexual o las orientación sexuales de una persona). En el caso presente, será necesario el tratamiento por parte de LEGALITAS SALUD de datos especiales relativos a la salud,</p>
        <p>Tal y como se describe en el apartado sobre la finalidad del tratamiento en la presente Política, los tratamientos de datos de salud podrán formar parte de los servicios que LEGÁLITAS prestará al usuario a través de la Skill Ocaso mediante Alexa, esto es, Seguimiento diario de síntomas automatizado, llamada automatizada de Ocaso mediante dispositivo, asistencia de un médico 24 horas, aviso a persona designada al efecto por el asegurado (puntualmente o notificaciones diarias según la opción elegida), mediante SMS o mail, del estado de salud del asegurado y el informe semanal de control y seguimiento, a persona designada al efecto por el asegurado, sobre su estado de bienestar. Lo anterior, podría incluir, la comunicación de sus datos al servicio público 112 en caso de ser necesaria su intervención.</p>
        <p><strong>Consentimiento explícito para tratamiento de datos de salud (art. 9.2.a RGPD)</strong></p>
        <p>La habilitación para cada una de las finalidades de tratamiento de datos de salud se basa en el consentimiento explícito. Este consentimiento será recogido por LEGÁLITAS mediante llamada telefónica que realizará al usuario con carácter previo a su utilización del SKILL, que quedará grabada, siendo éste advertido previamente de la grabación de la llamada.</p>
        <p>En esa misma llamada se le informará, entre otras cuestiones, de los datos básicos o primera capa de esta política de privacidad y de dónde consultarla de forma completa, se le informará sobre la forma de revocar sus consentimientos y de sus derechos de privacidad y cómo ejercerlos.</p>
        <p><strong>El tratamiento de datos necesario para la prestación de servicios de asistencia sanitaria (art. 9.2.h RGDP)</strong></p>
        <p>Para un mayor abundamiento, la habilitación para que LEGÁLITAS pueda tratarlos viene establecida en el apartado 2 del artículo 9 RGPD y en concreto en la letra h), ya que es un tratamiento de datos necesario para la prestación de asistencia sanitaria</p>
        <p><strong>El tratamiento es necesario para el cumplimiento de una obligación legal aplicable al responsable del tratamiento (art. 6.1c RGPD)</strong></p>
        <ul type="disc">
          <li>Atender los ejercicios de derechos de los interesados en relación con la privacidad ante la empresa, esto es los derechos a Acceder, Rectificar, Suprimir, Oposición, Limitación y Olvido es un tratamiento al que el Responsable está obligado en cumplimiento del RGPD.</li>
        </ul>
        <p><strong>Con base en el interés legítimo (art. 6.1.f del RGPD):</strong></p>
        <p>Control de calidad del servicio prestado, que no incluirá datos de salud, sino únicamente de satisfacción del servicio</p>
        <p><strong>¿A quién se comunican sus datos?</strong></p>
        <p>Los datos personales tratados por LEGALITAS SALUD para alcanzar las finalidades detalladas anteriormente podrán ser comunicados a los siguientes destinatarios en función de la base legitimadora de la comunicación.</p>
        <p>En virtud de lo anterior, las siguientes comunicaciones de datos persiguen garantizar el correcto desarrollo de la relación contractual, así como dar cumplimiento a obligaciones legales que exigen realizar las mencionadas comunicaciones:</p>
        <p>· Organismos y Administraciones Públicas en caso de que exista alguna obligación legal, como por ejemplo alguna reclamación o solicitud de información de la Administración Pública.</p>
        <p>· Asimismo, LEGÁLITAS SALUD podrá comunicar los datos de los usuarios a aquellos organismos públicos o partes implicadas, cuando fuera estrictamente imprescindible para la ejecución del servicio contratado y/o exista una obligación o habilitación legal que así lo requiera.</p>
        <p>· Entidades financieras para la gestión de cobros y pagos.</p>
        <p>· Profesionales del ámbito sanitario o de cualquier otro tipo, colaboradores de LEGALITAS SALUD cuya intervención sea necesaria para la ejecución del servicio solicitado.</p>
        <p>· La sociedad integrante del Grupo LEGÁLITAS, LEGALITAS ASISTENCIA LEGAL, S.L, con NIF B82305848 y domicilio social en Pozuelo de Alarcón, Avenida Leopoldo Calvo-Sotelo Bustelo, 6, 28224 de Madrid; inscrita en el Registro Mercantil de Madrid, tomo 15770, folio 187, sección 8, hoja M 232821 para para la gestión de cobros y pagos, y para la prestación de servicios de mantenimiento y gestión del contrato, tales como el soporte informático, actuando en esos casos como Encargada del tratamiento por cuenta de LEGALITAS SALUD.</p>
        <p>· Los proveedores de servicios informáticos, incluso servicios de “computación en la nube” con los que LEGALITAS SALUD tenga contratos de colaboración, y cuya intervención sea necesaria para la prestación del servicio solicitado por el usuario.</p>
        <p>· Al tercero designado por el usuario para recibir esa información, con base en el previo su consentimiento informado, o su tutor o familiar designado en la aplicaciónAl servicio de emergencias 112 en los casos previstos para ello.</p>
        <p><strong>¿Se realizan transferencias internacionales con sus datos personales? </strong></p>
        <p>NO. LEGÁLITAS SALUD no realiza transferencias internacionales de datos personales de los datos de los usuarios del servicio Skill Ocaso</p>
        <p><strong>¿Por cuánto tiempo conservaremos sus datos?</strong></p>
        <p>Los datos personales serán conservados mientras se mantenga la relación contractual, durante el tiempo necesario para la prestación del servicio o mientras el interesado no retire su consentimiento o solicite la cancelación o supresión de sus datos personales.</p>
        <p>Una vez finalice el plazo de conservación antes indicado, sus datos personales se bloquearán, permaneciendo en los sistemas de LEGALITAS SALUD disponibles únicamente en caso de solicitud de las Administraciones Públicas y de los Juzgados y Tribunales durante el plazo de prescripción de las acciones que pudieran derivar y, transcurrido éste, se procederá a su completa eliminación mediante la anonimización de los mismos.</p>
        <p><strong>¿Cuáles son sus derechos en relación con la protección de datos?</strong></p>
        <p>Cualquier interesado tiene derecho a obtener confirmación sobre si en LEGALITAS SALUD se están tratando datos personales que le conciernen, o no.</p>
        <p>Del mismo modo, tal y como queda previsto en el Reglamento General de Protección de Datos, los usuarios de los servicios del asistente virtual Ocaso, podrán ejercitar los siguientes derechos ante LEGALITAS SALUD:</p>
        <p>· Acceder a sus datos con el fin de conocer el tratamiento de los datos personales del interesado llevados a cabo por LEGALITAS SALUD.</p>
        <p>· Solicitar la rectificación o supresión de sus datos cuando el interesado los considere inexactos, y que sean objeto de tratamiento por parte de LEGALITAS SALUD o incluso, a solicitar su supresión cuando, entre otros motivos, los datos ya no fueran necesarios para los fines que fueron recogidos.</p>
        <p>· Solicitar la limitación del tratamiento de sus datos. En estos casos, LEGALITAS SALUD conservará los datos objeto de la limitación únicamente para el cumplimiento de sus obligaciones contractuales o para el ejercicio o defensa de reclamaciones tal y como queda previsto en el Reglamento General de Protección de Datos.</p>
        <p>· A la portabilidad de sus datos, en los casos en los que el interesado solicite recibir los datos de carácter personal que le incumban, y que haya facilitado a LEGALITAS SALUD. Estos datos se proporcionarán al interesado en un formato estructurado de uso común y lectura mecánica.</p>
        <p>· Oponerse al tratamiento de sus datos, en cuyo caso, LEGALITAS SALUD dejaría de tratarlos salvo para el cumplimiento de sus obligaciones contractuales o para el ejercicio o defensa de reclamaciones tal y como queda previsto en el Reglamento General de Protección de Datos.</p>
        <p>Igualmente, el interesado, podrá ejercer los derechos anteriormente descritos, y retirar los consentimientos otorgados mediante solicitud escrita dirigida A LEGALITAS SALUD, S.A. en Avda. Leopoldo Calvo Sotelo Bustelo, 6, Pozuelo de Alarcón (28224 MADRID), o bien remitiendo un correo electrónico a la dirección <a href="mailto:saclopd@legalitas.es">saclopd@legalitas.es</a> indicando el derecho que desea ejercitar.</p>
        <p>Adicionalmente, se informa al Usuario y/o Cliente que puede dirigir cualquier tipo de reclamación en materia de protección de datos personales a la Agencia Española de Protección de Datos www.agpd.es, Autoridad de Control del Estado español.</p>
        <p><strong>ACTUALIZACIÓN DE LA POLÍTICA</strong></p>
        <p>La presente política ha sido actualizada conforme a las exigencias de la normativa comunitaria de Protección de Datos Personales, el Reglamento General de Protección de Datos (RGPD).</p>
        <p>Asimismo, se informa que la presente política podrá ser modificada por causa de cambios en los requisitos establecidos por la legislación vigente en cada momento, por decisiones judiciales y cambios jurisprudenciales, así como por cambios contractuales en la relación de LEGALITAS SALUD y OCASO. En caso de que exista alguna modificación, esta será publicada en este mismo sitio.</p>
      </div>

              </Grid>
            </Grid>
          </Container>
        </Box>

      </Box>
      <OcasoFooter />
    </>
  );
}
