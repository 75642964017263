/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact {
    onCreateContact {
      id
      name
      surname
      email
      telephone
      customerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact {
    onUpdateContact {
      id
      name
      surname
      email
      telephone
      customerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact {
    onDeleteContact {
      id
      name
      surname
      email
      telephone
      customerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFollowUp = /* GraphQL */ `
  subscription OnCreateFollowUp {
    onCreateFollowUp {
      id
      date
      User {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      reason
      notes
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      followUpUserId
      followUpCustomerId
    }
  }
`;
export const onUpdateFollowUp = /* GraphQL */ `
  subscription OnUpdateFollowUp {
    onUpdateFollowUp {
      id
      date
      User {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      reason
      notes
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      followUpUserId
      followUpCustomerId
    }
  }
`;
export const onDeleteFollowUp = /* GraphQL */ `
  subscription OnDeleteFollowUp {
    onDeleteFollowUp {
      id
      date
      User {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      reason
      notes
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      followUpUserId
      followUpCustomerId
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      name
      email
      surname
      telephone
      profile
      Customers {
        items {
          id
          userID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      name
      email
      surname
      telephone
      profile
      Customers {
        items {
          id
          userID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      name
      email
      surname
      telephone
      profile
      Customers {
        items {
          id
          userID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateHistory = /* GraphQL */ `
  subscription OnCreateHistory {
    onCreateHistory {
      id
      value
      type
      description
      Customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Tip {
        id
        title
        description
        category
        premessage
        question
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      FollowUp {
        id
        date
        User {
          id
          name
          email
          surname
          telephone
          profile
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Customer {
          id
          email
          name
          telephone
          surname
          alias
          NIF
          birthdate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reason
        notes
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        followUpUserId
        followUpCustomerId
      }
      User {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      historyCustomerId
      historyTipId
      historyFollowUpId
      historyUserId
    }
  }
`;
export const onUpdateHistory = /* GraphQL */ `
  subscription OnUpdateHistory {
    onUpdateHistory {
      id
      value
      type
      description
      Customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Tip {
        id
        title
        description
        category
        premessage
        question
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      FollowUp {
        id
        date
        User {
          id
          name
          email
          surname
          telephone
          profile
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Customer {
          id
          email
          name
          telephone
          surname
          alias
          NIF
          birthdate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reason
        notes
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        followUpUserId
        followUpCustomerId
      }
      User {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      historyCustomerId
      historyTipId
      historyFollowUpId
      historyUserId
    }
  }
`;
export const onDeleteHistory = /* GraphQL */ `
  subscription OnDeleteHistory {
    onDeleteHistory {
      id
      value
      type
      description
      Customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Tip {
        id
        title
        description
        category
        premessage
        question
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      FollowUp {
        id
        date
        User {
          id
          name
          email
          surname
          telephone
          profile
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Customer {
          id
          email
          name
          telephone
          surname
          alias
          NIF
          birthdate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reason
        notes
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        followUpUserId
        followUpCustomerId
      }
      User {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      historyCustomerId
      historyTipId
      historyFollowUpId
      historyUserId
    }
  }
`;
export const onCreateTip = /* GraphQL */ `
  subscription OnCreateTip {
    onCreateTip {
      id
      title
      description
      category
      premessage
      question
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTip = /* GraphQL */ `
  subscription OnUpdateTip {
    onUpdateTip {
      id
      title
      description
      category
      premessage
      question
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTip = /* GraphQL */ `
  subscription OnDeleteTip {
    onDeleteTip {
      id
      title
      description
      category
      premessage
      question
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      email
      name
      telephone
      surname
      alias
      NIF
      birthdate
      users {
        items {
          id
          userID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contacts {
        items {
          id
          name
          surname
          email
          telephone
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      email
      name
      telephone
      surname
      alias
      NIF
      birthdate
      users {
        items {
          id
          userID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contacts {
        items {
          id
          name
          surname
          email
          telephone
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      email
      name
      telephone
      surname
      alias
      NIF
      birthdate
      users {
        items {
          id
          userID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contacts {
        items {
          id
          name
          surname
          email
          telephone
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserCustomer = /* GraphQL */ `
  subscription OnCreateUserCustomer {
    onCreateUserCustomer {
      id
      userID
      customerID
      user {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserCustomer = /* GraphQL */ `
  subscription OnUpdateUserCustomer {
    onUpdateUserCustomer {
      id
      userID
      customerID
      user {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserCustomer = /* GraphQL */ `
  subscription OnDeleteUserCustomer {
    onDeleteUserCustomer {
      id
      userID
      customerID
      user {
        id
        name
        email
        surname
        telephone
        profile
        Customers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      customer {
        id
        email
        name
        telephone
        surname
        alias
        NIF
        birthdate
        users {
          nextToken
          startedAt
        }
        Contacts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
