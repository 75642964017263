import React from "react";
import { useLocation } from "react-router-dom";

import ReactBSAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";


import componentStyles from "assets/theme/views/admin/profile.js";
import componentStylesButton from "assets/theme/components/button.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";
import componentStylesTimeline from "assets/theme/views/admin/timeline.js";
import componentStylesBadge from "assets/theme/components/badge.js";
import componentStylesInfoCard from "assets/theme/components/cards/alternative/card-info-bg.js";


import { DataStore } from "@aws-amplify/datastore";
import { Customer } from "../../../models";
import { Warning } from "@material-ui/icons";

// Styles
const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);
const useStylesTimeline = makeStyles(componentStylesTimeline);
const useStylesBadge = makeStyles(componentStylesBadge);
const useStylesInfoCard = makeStyles(componentStylesInfoCard);
const useStylesButton = makeStyles(componentStylesButton);


const customStyles = makeStyles(({
  cardStatsCustom: {
    marginBottom: '1.5rem!important',
  },
  historyContent: {
    overflow: 'auto',
    maxHeight: '600px'
  }  
}))

function CustomerDelete(props) {

  const location = useLocation();
  const queryParams = new URLSearchParams(props.location.search);
  const [customerId, setCustomerId] = React.useState('');
  const [customer, setCustomer] = React.useState(props.customer);
  
  const [alert, setAlert] = React.useState(false)
  
  React.useEffect(() => {
    
    console.log('CustomerDelete', props);

    setCustomerId(queryParams.get("id"));
    console.log('customerId', customerId);
    
  }, [location]);

  React.useEffect(() => {
      fetchCustomer()
  }, [customerId]);

  

  async function fetchCustomer() {
    const apiData = await DataStore.query(Customer, customerId);
    setCustomer(apiData)
    console.log('Customer -->', apiData)
    // await fetchUserCustomers(apiData)
  }

  async function deleteCustomer(){
    console.log('BORRANDO USUARIO...', customer.id)
    if (customer && customer.id) {
      const todelete = await DataStore.query(Customer, customer.id);
      const result = await DataStore.delete(todelete);
      if (result) {
        console.log('resultado de borrado:', result)
        deleteConfirmation()
      }
    }
    
  }

  async function deleteCustomerConfirmation() {
    console.log('deleteCustomer', customer.id)
    
    try {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="¿Estás seguro que quieres eliminar este cliente?"
          onConfirm={() => {
            deleteCustomer()
            setAlert(null)
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="danger"
          confirmBtnText="Sí, quiero eliminar este cliente."
          cancelBtnText="Cancelar"
          showCancel
          btnSize=""
        >
          {/* <h3>Por favor, confirma baja de cliente...<br></br> */}
          <h3>No se podrá revertir esta acción.</h3>
          <b>Nombre</b>: {customer.name} {customer.surname || ''}
          <br></br>
          <b>Email</b>: {customer.email}
          
        </ReactBSAlert>
      );
      return true
    } catch (error) {
      console.log("Error eliminando cliente", error);
      return false
    }
  }

  async function deleteConfirmation() {
    try {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Hecho"
          onConfirm={() => {
            setAlert(null)
            props.history.push('/admin/clients');
          }}
          onCancel={() => {
            setAlert(null)
            props.history.push('/admin/clients');
          }}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          El cliente ha sido eliminado correctamente.
        </ReactBSAlert>
      );
      return true
    } catch (error) {
      console.log("Error mostrando confirmación", error);
      return false
    }
  }

  

  



  const classes = { ...useStyles(), ...useStylesCardImg(), ...useStylesTimeline(), ...useStylesBadge(), ...customStyles(), ...useStylesInfoCard(), ...useStylesButton() };
  
  return (
    <>
      {alert}
      {/* Información del contacto en texto */}
      <Card
        classes={{
          root: classes.cardStatsCustom,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                <Box
                  component={Warning}
                  width="1.25rem!important"
                  height="1.25rem!important"
                  marginRight=".5rem"
                  color="red"
                ></Box>
                  Eliminar cliente en el CRM
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box
                  justifyContent="flex-end"
                  display="flex"
                  flexWrap="wrap"
                >
                  <Button
                    variant="contained"
                    classes={{
                      root: classes.buttonContainedError,
                    }}
                    size="small"
                    onClick={() => {
                      deleteCustomerConfirmation()
                    }}
                  >
                    Eliminar cliente
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
      </Card>
    </>
  );
}

export default CustomerDelete;
