

function normalizeEmail(emailString) {
    if (typeof emailString === 'string') {
        return emailString.toLowerCase()
    }
    return ''
}


export {
    normalizeEmail
}