import React from "react";
import clsx from "clsx";
// react plugin used to create datetimepicker
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
// import Notifications from "@material-ui/icons/Notifications";
// import ThumbUp from "@material-ui/icons/ThumbUp";
// import ThumbDown from "@material-ui/icons/ThumbDown";
import WatchLater from "@material-ui/icons/WatchLater";
// core components

import componentStyles from "assets/theme/views/admin/profile.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";
import componentStylesTimeline from "assets/theme/views/admin/timeline.js";
import componentStylesBadge from "assets/theme/components/badge.js";

// Amplify
// import { API } from 'aws-amplify';
// import { listHistories } from "graphql/queries";
// import moment from "moment";

// Styles
const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);
const useStylesTimeline = makeStyles(componentStylesTimeline);
const useStylesBadge = makeStyles(componentStylesBadge);

function CustomerTimeline(props) {

    const [history, setHistory] = React.useState([]);
    
    React.useEffect(() => {
        setHistory(props.history);
        console.log(history)
    }, [props.history]);

    const getAdValue = (value) => {
        if (value === 4) {
            return 'Mal'
        } else if (value === 6) {
            return 'Bien'
        }
        return 'Regular'
    }
   

    const classes = { ...useStyles(), ...useStylesCardImg(), ...useStylesTimeline(), ...useStylesBadge() };
    const theme = useTheme();
    return (
        <>
          <div className={clsx(classes.timeline, classes.timelineOneSide)}>
            {(history || []).map((prop, key) => (
                <div key={key} className={classes.timelineBlock}>
                <Badge
                    badgeContent={<prop.icon />}
                    color={prop.color === "Error" ? "error" : undefined}
                    classes={{
                    root: classes.timelineBadgeRoot,
                    badge: clsx(
                        classes.timelineBadge,
                        classes.badgePositionRelative,
                        {
                        [classes["badge" + prop.color]]: prop.color !== "Error",
                        }
                    ),
                    }}
                ></Badge>
                <div className={classes.timelineContent}>
                    <Box
                    display="flex"
                    justifyContent="space-between"
                    paddingTop=".25rem"
                    >
                    <Box
                        component="span"
                        fontSize=".875rem"
                        fontWeight="600"
                        color={theme.palette.gray[600]}
                    >
                        {prop.title}
                    </Box>
                    <Box
                        component="small"
                        fontSize="80%"
                        fontWeight="600"
                        color={theme.palette.gray[600]}
                    >
                        <Box
                        component={WatchLater}
                        position="relative"
                        top="-2px"
                        marginRight=".5rem"
                        />
                        {prop.time}
                    </Box>
                    </Box>
                    <Typography
                    component="h5"
                    variant="h5"
                    className={classes.typographyH5}
                    >
                    {prop.description}
                    {prop.value > 0 ? (<br/>) : ""}
                    {prop.value > 0 ? (
                        prop.type === 'CONSEJO' ? "Respuesta: " : "Valoración: ") : ""}
                    {prop.value > 0 ? (<Badge
                        badgeContent={prop.type === 'CONSEJO' ? getAdValue(prop.value) : prop.value}
                        color={prop.colorBadge}
                        classes={{
                            root: classes.badgeMargin,
                            badge: clsx(
                            classes.badgePositionRelative,
                            classes.badgeRound,
                            {
                                [classes["badge" + prop.color]]:
                                prop.color !== "Error",
                            }
                            ),
                        }}
                        ></Badge>) : ""}
                    </Typography>
                </div>
                </div>
            ))}
            </div>
        </>
      );
//   return (
//     <>
//       <div
//             className={clsx(classes.timeline, classes.timelineOneSide)}
//         >
//             {(history || []).map((prop, key) => (
//             <div key={key} className={classes.timelineBlock}>
//                 <Badge
//                 badgeContent={<prop.icon />}
//                 color={prop.colorBadge}
//                 classes={{
//                     root: classes.timelineBadgeRoot,
//                     badge: clsx(
//                     classes.timelineBadge,
//                     classes.badgePositionRelative,
//                     {
//                         [classes["badge" + prop.color]]:
//                         prop.color !== "Error",
//                     }
//                     ),
//                 }}
//                 ></Badge>
//                 <div className={classes.timelineContent}>
//                 <Box component="small" fontSize="80%" fontWeight="600">
//                     {new Date(prop.createdAt).toLocaleString("es")}
//                 </Box>
//                 <Typography
//                     component="h5"
//                     variant="h5"
//                     className={classes.typographyH5}
//                 >
//                     {prop.title}
//                 </Typography>
//                 <Box
//                     component="p"
//                     marginBottom="0"
//                     fontWeight="300"
//                     lineHeight="1.7"
//                     fontSize=".875rem"
//                     marginTop=".5rem"
//                 >
//                     {prop.description}
//                 </Box>
                // {prop.value > 0 ? (
                //     <Box 
                //         component="p"
                //         fontWeight="300"
                //         lineHeight="1.7"
                //         fontSize=".875rem" 
                //         marginTop="1rem">
                //     <Typography
                //         component="span"
                //         variant="span"
                        
                //     >
                //         Valoración
                //     </Typography>{" "}
                //     <Badge
                //     badgeContent={prop.value}
                //     color={prop.colorBadge}
                //     classes={{
                //         root: classes.badgeMargin,
                //         badge: clsx(
                //         classes.badgePositionRelative,
                //         classes.badgeRound,
                //         {
                //             [classes["badge" + prop.color]]:
                //             prop.color !== "Error",
                //         }
                //         ),
                //     }}
                //     ></Badge>
                    
                // </Box>) : ''}
//                 </div>
//             </div>
//             ))}
//         </div>
//     </>
//   );
}

export default CustomerTimeline;







