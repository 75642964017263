// core components
// admin pages
// // // // // admin dashboards
import Dashboard from "views/admin/Dashboards/Dashboard.js";
// // // // // admin rest
// import Calendar from "views/admin/Calendar.js";

// @material-ui/icons components
// import Event from "@material-ui/icons/Event";
import House from "@material-ui/icons/House";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
// import ListAlt from "@material-ui/icons/ListAlt";

// Ocaso components
import CustomerList from "views/admin/Customers/CustomerList";
// import TipList from "views/admin/Tips/TipList";
import Call from "@material-ui/icons/Call";
import FollowUpsView from "views/admin/FollowUps";

var routes = [
  {
    path: "/dashboard",
    name: "Panel de control",
    icon: House,
    iconColor: "Primary",
    state: "dashboardsCollapse",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: false,
    name: "Clientes",
    path: "/clients",
    icon: PeopleAlt,
    iconColor: "Info",
    state: "dashboardsCollapse",
    layout: "/admin",
    component: CustomerList,
  },
  {
    collapse: false,
    name: "Seguimientos",
    path: "/followups",
    icon: Call,
    iconColor: "Primary",
    state: "dashboardsCollapse",
    layout: "/admin",
    component: FollowUpsView,
  },
  // {
  //   collapse: false,
  //   name: "Consejos de Alexa",
  //   path: "/tips",
  //   icon: ListAlt,
  //   iconColor: "Info",
  //   state: "dashboardsCollapse",
  //   layout: "/admin",
  //   component: TipList,
  // },
  
];
export default routes;
